export const LANDING = "/";
export const ABOUT = "/about";
export const CONTACT = "/contact";
export const MEMBERS = "/members";
export const EVENTS = "/events";
export const LOGIN = "/login";
export const PANEL = "/panel";
export const EDITDATA = "/edit-data";
export const EDITLOGIN = "/edit-login";
export const LOGOUT = "/logout";
export const MEMBEREDIT = "/eboard/members";
export const EVENTEDIT = "/eboard/events";
export const USEREDIT = "/eboard/users";
export const NOTFOUND = "/404";
export const APPLY = "/interview/apply";
export const INTEREST = "/interview/interest-form";
export const INTERESTQR = "/interest-form-qr";